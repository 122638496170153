@charset "UTF-8";
@import "_define.scss";

.homeMv {
  $this: &;
  padding-left: 80px;
  position: relative;
  width: 100%;
  height: 720px;
  @include mq {
    padding-left: 32px;
    height: auto;
  }
  &.is_lower {
    height: 430px;
    #{$this}__imgBox {
      height: 430px;
    }
  }
  &__imgBox {
    height: 720px;
    position: relative;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &__ttl {
      color: #fff;
      font-size: 48px;
      font-family: $mincho;
      font-weight: 400;
      position: absolute;
      bottom: 120px;
      left: 80px;
    }
    @include mq {
      height: auto;
    }
  }
  &__ttl {
    color: #fff;
    font-family: $mincho;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1150px;
    padding: 0 15px;
    width: 100%;
    margin: auto;
    h1 {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.83;
    }
    span {
      display: block;
      font-size: 48px;
      font-weight: normal;
      line-height: 1.2;
    }
  }
  &__scrollBox {
    overflow: hidden;
    position: absolute;
    bottom: 22px;
    left: 30px;
    padding-right: 2px;
    @include mq {
      bottom: 0;
      left: 8px;
    }
    &::before {
      background: #000;
      border-radius: 50%;
      content: "";
      position: absolute;
      top: -2px;
      right: 0;
      width: 100%;
      height: 100%;
      width: 6px;
      height: 6px;
      animation: loop 2.5s linear infinite;
    }
    img {
      width: 16px;
    }
  }
}

@keyframes loop {
  0% {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(100px);
  }
}

.homeSec01 {
  padding-top: 120px;
  &__box {
    display: grid;
    grid-template-columns: 560px auto;
    align-items: center;
    gap: 40px;
    margin-top: 32px;
    @include mq {
      grid-template-columns: 1fr;
    }
  }
  &__txtBox {
    &__ttl {
      font-family: $mincho;
      font-size: 24px;
      font-weight: normal;
      line-height: 1.83;
      @include mq {
        font-size: 18px;
      }
    }
    &__txt {
      font-size: 14px;
      font-weight: normal;
      line-height: 2;
      margin-top: 32px;
      @include mq {
        margin-top: 24px;
      }
    }
  }
}

.homeSec02 {
  padding-top: 105px;
  @include mq {
    padding-top: 93px;
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 32px;
    @include mq {
      grid-template-columns: 1fr;
      margin-top: 10px;
    }
    &__item {
      a {
        display: block;
        height: 280px;
        position: relative;
        @include mq {
          height: 220px;
        }
        &:hover {
          opacity: 1;
          &::before {
            background: rgba(#214F05, 0.48);
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(#000, 0.48);
          transition: .3s;
        }
        &::after {
          border: 1px solid #fff;
          content: "";
          position: absolute;
          top: 10px;
          left: 10px;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          @include mq {
            top: 16px;
            left: 16px;
            width: calc(100% - 32px);
            height: calc(100% - 32px);
          }
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
    &__txtBox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: 100%;
    }
    &__ttl {
      color: #fff;
      font-family: $mincho;
      font-size: 36px;
      font-weight: normal;
      line-height: 1.22;
      text-align: center;
      @include mq {
        font-size: 24px;
      }
    }
    &__txt {
      color: #fff;
      font-family: $mincho;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.22;
      text-align: center;
    }
  }
}


.homeSec03 {
  padding-top: 105px;
  @include mq {
    padding-top: 69px;
  }
  &__inner {
    position: relative;
    height: 100%;
  }
  &__txtBox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 540px;
    @include mq {
      position: static;
      transform: none;
    }
    &__ttl {
      font-family: $mincho;
      font-size: 24px;
      font-weight: normal;
      line-height: 1.83;
      margin-top: 40px;
      @include mq {
        font-size: 18px;
        margin-top: 16px;
      }
    }
    &__txt {
      font-size: 14px;
      font-weight: normal;
      line-height: 2;
      margin-top: 32px;
      @include mq {
        margin-top: 30px;
      }
    }
  }
  &__btnBox {
    margin-top: 40px;
    @include mq {
      margin: 20px auto 0;
      a {
        margin: auto;
      }
    }
  }
  &__imgBox {
    max-width: 941px;
    margin-left: auto;
  }
  &__splideBox {
    margin-top: 40px;
    margin-left: -15px;
    width: calc(100% + 30px);
  }
}

.homeSec04 {
  padding-top: 105px;
  @include mq {
    padding-top: 80px;
  }
  &__box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 64px;
    margin-top: 68px;
    @include mq {
      grid-template-columns: 1fr;
    }
  }
  &__list {
    counter-reset: number;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 84px 33px;
    @include mq {
      gap: 73px 0;
      grid-template-columns: 1fr;
    }
    &__item {
      position: relative;
      &:nth-child(2) {
        margin-top: 80px;
      }
      &:nth-child(3) {
        margin-top: -80px;
      }
      @include mq {
        &:nth-child(2) {
          margin-top: 0;
        }
        &:nth-child(3) {
          margin-top: 0;
        }
      }
      &::before {
        counter-increment: number;
        content: '0' counter(number);
        font-family: $mincho;
        font-size: 48px;
        letter-spacing: .06em;
        position: absolute;
        top: -48px;
        left: 5px;
        @include mq {
          left: 40px;
        }
      }
    }
    &__ttl {
      font-family: $mincho;
      font-size: 16px;
      font-weight: normal;
      margin-top: 20px;
      @include mq {
        margin: 20px 40px 0;
      }
    }
    &__txt {
      font-size: 12px;
      font-weight: normal;
      line-height: 2;
      margin-top: 10px;
      @include mq {
        margin: 10px 40px 0;
      }
    }
  }
  &__txtBox {
    &__ttl {
      font-family: $mincho;
      font-size: 24px;
      font-weight: normal;
      line-height: 1.83;
      @include mq {
        font-size: 18px;
      }
    }
    &__txt {
      font-size: 14px;
      font-weight: normal;
      line-height: 2;
      margin-top: 40px;
      @include mq {
        margin-top: 22px;
      }
    }
  }
  &__contactBox {
    border: solid 2px #214F05;
    color: #214F05;
    font-family: $mincho;
    margin-top: 40px;
    padding: 23px;
    @include mq {
      padding: 23px 0;
      margin-top: 30px;
    }
    &__txt {
      font-size: 12px;
      font-weight: normal;
      line-height: 1.2;
      text-align: center;
    }
    &__tel {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 32px;
      font-weight: normal;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 17px;
      @include mq {
        font-size: 27px;
      }
    }
    &__mail {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 24px;
      font-weight: normal;
      line-height: 1.2;
      text-align: center;
      @include mq {
        font-size: 21px;
      }
    }
    &__line {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      color: #000;
      margin-top: 10px;
      @include mq {
        font-size: 14px;
      }
    }
  }
}

.homeSec05 {
  padding-top: 150px;
  @include mq {
    padding-top: 65px;
    &__box {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 32px;
    margin-top: 32px;
    @include mq {
      grid-template-columns: 1fr;
    }
    &__item {
      border-top: solid 1px #D0D0D0;
      font-size: 14px;
      display: grid;
      grid-template-columns: 200px auto;
      padding: 20px 0;
      @include mq {
        grid-template-columns: 160px auto;
      }
      &:nth-last-child(2) {
        border-bottom: solid 1px #D0D0D0;
        @include mq {
          border-bottom: none;
        }
      }
      &:last-child {
        border-bottom: solid 1px #D0D0D0;
      }
    }
    &__ttl {
      font-weight: normal;
      @include mq {
        font-size: 14px;
      }
    }
    &__txt {
      font-size: 14px;
      font-weight: normal;
    }
  }
  &__mapBox {
    margin-top: 32px;
    iframe {
      width: 100%;
      height: 400px;
      @include mq {
        height: 212px;
      }
    }
  }
}
