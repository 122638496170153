@charset "UTF-8";
@import "_define.scss";

.worksSec01 {
  padding-top: 100px;
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    &__item {
      @include mq {
        width: calc(50% - 6px);
      }
      a {
        border: 1px solid #214F06;
        font-family: $mincho;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 160px;
        height: 40px;
        &:hover, &.is_active {
          background-color: #214f06;
          color: #fff;
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
}

.worksSec02 {
  margin-top: 24px;
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px 12px;
    a {
      cursor: pointer;
    }
    @include mq {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.mfp-content {
  max-width: 1120px;
}

.mfp-arrow-left {
  @include mq {
    left: -10px;
  }
}

.mfp-arrow-right {
  @include mq {
    right: -10px;
  }
}

.mfp-close {
  @include mq {
    right: auto;
    left: 0;
  }
}

.popup {
  &__box {
    display: flex;
    gap: 40px;
    @include mq {
      flex-direction: column;
    }
  }
  &__imgBox {
    width: 50%;
    @include mq {
      width: 100%;
    }
  }
  &__txtBox {
    color: #fff;
    width: 50%;
    @include mq {
      margin: 0 auto;
      width: 80%;
    }
    &__ttl {
      font-family: $mincho;
      font-size: 24px;
      font-weight: normal;
    }
    &__txt {
      font-size: 14px;
      font-weight: normal;
      margin-top: 30px;
    }
  }
  &__list {
    margin-top: 20px;
    &__item {
      display: grid;
      grid-template-columns: 124px auto;
      border-bottom: 1px solid #fff;
      padding: 10px 0;
    }
    &__ttl {
      font-size: 14px;
      font-weight: normal;
    }
    &__txt {
      font-size: 14px;
      font-weight: normal;
    }
  }
}
