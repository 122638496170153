.footerContact {
	background: url(../img/footer/footer_bg01.webp) no-repeat center center / cover;
	color: #fff;
	padding: 80px 0;
	@include mq {
		padding: 60px 0;
	}
	&__box {
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		justify-content: center;
		gap: 48px;
		@include mq {
			gap: 24px;
			grid-template-columns: 1fr;
		}
	}
	&__txt {
		font-size: 14px;
		font-weight: normal;
		line-height: 2;
		margin-top: 32px;
		@include mq {
			margin-top: 15px;
		}
	}
	&__contactBox {
		border: solid 1px #fff;
    font-family: $mincho;
    margin-top: 40px;
    padding: 23px;
		@include mq {
			margin-top: 0;
		}
    &__txt {
      font-size: 12px;
      font-weight: normal;
      line-height: 1.2;
      text-align: center;
    }
    &__tel {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 32px;
      font-weight: normal;
      line-height: 1.2;
      text-align: center;
			margin-top: 5px;
      margin-bottom: 17px;
    }
    &__mail {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 24px;
      font-weight: normal;
      line-height: 1.2;
      text-align: center;
    }
    &__line {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 10px;
			@include mq {
				justify-content: flex-start;
				line-height: 1.5;
			}
    }
	}
}

.footer {
	padding: 32px 0 7px;
	@include mq {
		padding: 32px 0 10px;
	}
	&__box {
		display: flex;
		justify-content: space-between;
		@include mq {
			justify-content: center;
		}
	}
	&__txt {
		font-size: 14px;
		font-weight: normal;
		line-height: 1.57;
		margin-top: 20px;
		@include mq {
			text-align: center;
			margin-top: 13px;
		}
	}
	&__list {
		display: flex;
		gap: 48px;
		@include mq {
			display: none;
		}
		&__item {
			font-family: $mincho;
			font-size: 15px;
			font-weight: normal;
			line-height: 1.4;
			&:hover {
				color: #214F05;
			}
		}
	}
	&__copyright {
		font-size: 12px;
		font-weight: normal;
		line-height: 1.83;
		text-align: center;
		margin-top: 63px;
		@include mq {
			margin-top: 51px;
		}
	}
}
