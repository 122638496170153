@charset "UTF-8";
@import "~normalize.css/normalize.css";
@import '~animate.css/animate.css';
@import "~@splidejs/splide/dist/css/splide.min.css";
@import "~magnific-popup/dist/magnific-popup.css";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Serif+JP:wght@200..900&display=swap");
/*============================================================================================================
	base
============================================================================================================*/
body {
  color: #000;
  line-height: 2;
  height: 100%;
  min-width: 1000px;
}
@media screen and (max-width: 767px) {
  body {
    min-width: auto;
  }
}

div, h1, h2, h3, h4, h5, p, li, dl, dt, dd, th, td, article, header, footer, nav, span, a {
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

img {
  height: auto;
  width: auto;
  max-width: 100%;
  line-height: 100%;
  vertical-align: bottom;
}

em {
  font-style: normal;
}

h1, h2, h3, h4, h5 {
  line-height: 1.4;
  margin: 0;
  word-wrap: break-word;
}

p, li, td, th, dt, dd {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.85;
  letter-spacing: 0.005em;
  margin: 0;
  word-wrap: break-word;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: top;
  word-break: break-all;
}

ul,
ol,
dl {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

/*	PC・SP 表示指定 */
@media screen and (min-width: 768px) {
  .is_sp {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .is_pc {
    display: none !important;
  }
}
/*------------------------------------------
	font
------------------------------------------*/
body {
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, sans-serif;
}

/*------------------------------------------
	hover
------------------------------------------*/
a {
  transition: all 0.3s ease-in-out;
}
a:hover {
  opacity: 0.7;
}

/*	枠内にfitするimg */
.imgFitBox, .imgLinkBox {
  overflow: hidden;
}
.imgFitBox img, .imgLinkBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover; object-position: center;";
}
.imgFitBox.contain, .imgLinkBox.contain { /* fit = contain */ }
.imgFitBox.contain img, .imgLinkBox.contain img {
  object-fit: contain;
  font-family: "object-fit: contain; object-position: center;";
}

/*	hoverで拡大するimg */
.imgLinkBox {
  display: block;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .imgLinkBox img {
    transition: all all 0.3s ease-in-out ease-in-out;
  }
  .imgLinkBox:hover {
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
  }
  .imgLinkBox:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.main {
  padding-bottom: 120px;
}
@media screen and (max-width: 767px) {
  .main {
    padding-bottom: 84px;
  }
}

/*	inner */
.c-inner01 {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1150px;
  width: 100%;
}

.c-inner02 {
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
}

/*	セクションタイトル */
.c-ttl01 {
  display: flex;
  align-items: center;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 40px;
  letter-spacing: 0.06em;
}
.c-ttl01.is_white {
  color: #fff;
}
.c-ttl01 span {
  font-size: 16px;
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  .c-ttl01 {
    font-size: 32px;
  }
  .c-ttl01 span {
    font-size: 10px;
  }
}

/*	ボタン	*/
.c-btn01 {
  background: #214F05;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Noto Serif JP", serif;
  font-size: 15px;
  max-width: 256px;
  width: 100%;
  height: 56px;
}
.c-btn01.is-center {
  margin: 0 auto;
}
/*	header */
.header {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  padding-right: 40px;
  z-index: 999;
  width: 100%;
  height: 80px;
}
@media screen and (max-width: 767px) {
  .header {
    height: 50px;
  }
}
.header.lower_header {
  margin-bottom: 0;
  position: static;
}
.header.lower_header::before {
  content: none;
}
.header__logo {
  display: block;
  max-width: 180px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .header__logo {
    max-width: 106px;
  }
}
.header__nav {
  opacity: 1;
  visibility: visible;
  z-index: 1999990;
  overflow-y: scroll;
}
@media screen and (max-width: 767px) {
  .header__nav {
    padding: 14px 16px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }
  .header__nav.close {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }
}
.header__nav__line {
  font-size: 14px;
  font-family: "Noto Serif JP", serif;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  margin-top: 9px;
}
.header__nav__copy {
  font-size: 12px;
  font-family: "Noto Serif JP", serif;
  text-align: center;
  margin-top: 12px;
}
.header__navList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .header__navList {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .header__navList__item {
    width: 100%;
  }
  .header__navList__item a {
    border-bottom: solid 1px #ccc;
    display: block;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .header__navList__item.is_has_child {
    position: relative;
    margin-bottom: 0;
  }
  .header__navList__item.is_has_child::after, .header__navList__item.is_has_child::before {
    background-color: #000;
    content: "";
    position: absolute;
    top: 30px;
    right: 10px;
    width: 12px;
    height: 1px;
  }
  .header__navList__item.is_has_child::after {
    transform: rotate(90deg);
  }
  .header__navList__item.is_has_child.is_open::after {
    opacity: 0;
  }
}
.header__navList__item + .header__navList__item {
  margin-left: 48px;
}
@media screen and (max-width: 767px) {
  .header__navList__item + .header__navList__item {
    margin-left: 0;
  }
}
.header__navList__item.is_btn a {
  border: solid 1px #214F05;
  background-color: #214F05;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
}
.header__navList__item.is_btn a:hover {
  background-color: #fff;
  color: #214F05;
}
.header__navList__item a {
  font-size: 15px;
  font-family: "Noto Serif JP", serif;
}
.header__navList__item a:hover {
  color: #214F05;
}
.header__navSubList {
  display: none;
}
.header__navSubList__item {
  width: 100%;
}
.header__navSubList__item:last-child {
  margin-bottom: 18px;
}
.header__navSubList__item a {
  background: url(../img/common/ico_arrow01.svg) no-repeat right 5px center/24px;
  border-bottom: solid 1px #ccc;
  padding-left: 24px;
  width: 100%;
}
.header__contactBox {
  border: solid 2px #214F05;
  font-family: "Noto Serif JP", serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}
.header__contactBox__txt {
  font-size: 14px;
  text-align: center;
}
.header__contactBox__tel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  font-size: 28px;
  font-weight: normal;
  line-height: 1;
}
.header__contactBox__mail {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
}
.header__contactBox + .header__contactBox {
  margin-top: 12px;
}

/*	sp メニューを開くボタン */
.navBtn {
  background: transparent;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 24px;
  height: 18px;
  top: 16px;
  right: 16px;
  position: fixed;
  text-align: center;
  z-index: 99999;
}
.navBtn a {
  height: 100%;
  display: block;
}
.navBtn, .navBtn span {
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.navBtn span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  background: #000;
}
.navBtn span:nth-of-type(1) {
  top: 0;
}
.navBtn span:nth-of-type(2) {
  top: 0;
  bottom: 0;
}
.navBtn span:nth-of-type(3) {
  bottom: 0;
}
.navBtn.close span:nth-of-type(1) {
  transform: rotate(45deg);
  top: 8px;
}
.navBtn.close span:nth-of-type(2) {
  opacity: 0;
}
.navBtn.close span:nth-of-type(3) {
  transform: rotate(-45deg);
  bottom: 50%;
}

.footerContact {
  background: url(../img/footer/footer_bg01.webp) no-repeat center center/cover;
  color: #fff;
  padding: 80px 0;
}
@media screen and (max-width: 767px) {
  .footerContact {
    padding: 60px 0;
  }
}
.footerContact__box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 48px;
}
@media screen and (max-width: 767px) {
  .footerContact__box {
    gap: 24px;
    grid-template-columns: 1fr;
  }
}
.footerContact__txt {
  font-size: 14px;
  font-weight: normal;
  line-height: 2;
  margin-top: 32px;
}
@media screen and (max-width: 767px) {
  .footerContact__txt {
    margin-top: 15px;
  }
}
.footerContact__contactBox {
  border: solid 1px #fff;
  font-family: "Noto Serif JP", serif;
  margin-top: 40px;
  padding: 23px;
}
@media screen and (max-width: 767px) {
  .footerContact__contactBox {
    margin-top: 0;
  }
}
.footerContact__contactBox__txt {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
}
.footerContact__contactBox__tel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 32px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 17px;
}
.footerContact__contactBox__mail {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
}
.footerContact__contactBox__line {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .footerContact__contactBox__line {
    justify-content: flex-start;
    line-height: 1.5;
  }
}

.footer {
  padding: 32px 0 7px;
}
@media screen and (max-width: 767px) {
  .footer {
    padding: 32px 0 10px;
  }
}
.footer__box {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .footer__box {
    justify-content: center;
  }
}
.footer__txt {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .footer__txt {
    text-align: center;
    margin-top: 13px;
  }
}
.footer__list {
  display: flex;
  gap: 48px;
}
@media screen and (max-width: 767px) {
  .footer__list {
    display: none;
  }
}
.footer__list__item {
  font-family: "Noto Serif JP", serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.4;
}
.footer__list__item:hover {
  color: #214F05;
}
.footer__copyright {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.83;
  text-align: center;
  margin-top: 63px;
}
@media screen and (max-width: 767px) {
  .footer__copyright {
    margin-top: 51px;
  }
}

.homeMv {
  padding-left: 80px;
  position: relative;
  width: 100%;
  height: 720px;
}
@media screen and (max-width: 767px) {
  .homeMv {
    padding-left: 32px;
    height: auto;
  }
}
.homeMv.is_lower {
  height: 430px;
}
.homeMv.is_lower .homeMv__imgBox {
  height: 430px;
}
.homeMv__imgBox {
  height: 720px;
  position: relative;
}
.homeMv__imgBox img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.homeMv__imgBox__ttl {
  color: #fff;
  font-size: 48px;
  font-family: "Noto Serif JP", serif;
  font-weight: 400;
  position: absolute;
  bottom: 120px;
  left: 80px;
}
@media screen and (max-width: 767px) {
  .homeMv__imgBox {
    height: auto;
  }
}
.homeMv__ttl {
  color: #fff;
  font-family: "Noto Serif JP", serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1150px;
  padding: 0 15px;
  width: 100%;
  margin: auto;
}
.homeMv__ttl h1 {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.83;
}
.homeMv__ttl span {
  display: block;
  font-size: 48px;
  font-weight: normal;
  line-height: 1.2;
}
.homeMv__scrollBox {
  overflow: hidden;
  position: absolute;
  bottom: 22px;
  left: 30px;
  padding-right: 2px;
}
@media screen and (max-width: 767px) {
  .homeMv__scrollBox {
    bottom: 0;
    left: 8px;
  }
}
.homeMv__scrollBox::before {
  background: #000;
  border-radius: 50%;
  content: "";
  position: absolute;
  top: -2px;
  right: 0;
  width: 100%;
  height: 100%;
  width: 6px;
  height: 6px;
  animation: loop 2.5s linear infinite;
}
.homeMv__scrollBox img {
  width: 16px;
}

@keyframes loop {
  0% {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(100px);
  }
}
.homeSec01 {
  padding-top: 120px;
}
.homeSec01__box {
  display: grid;
  grid-template-columns: 560px auto;
  align-items: center;
  gap: 40px;
  margin-top: 32px;
}
@media screen and (max-width: 767px) {
  .homeSec01__box {
    grid-template-columns: 1fr;
  }
}
.homeSec01__txtBox__ttl {
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.83;
}
@media screen and (max-width: 767px) {
  .homeSec01__txtBox__ttl {
    font-size: 18px;
  }
}
.homeSec01__txtBox__txt {
  font-size: 14px;
  font-weight: normal;
  line-height: 2;
  margin-top: 32px;
}
@media screen and (max-width: 767px) {
  .homeSec01__txtBox__txt {
    margin-top: 24px;
  }
}

.homeSec02 {
  padding-top: 105px;
}
@media screen and (max-width: 767px) {
  .homeSec02 {
    padding-top: 93px;
  }
}
.homeSec02__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 32px;
}
@media screen and (max-width: 767px) {
  .homeSec02__list {
    grid-template-columns: 1fr;
    margin-top: 10px;
  }
}
.homeSec02__list__item a {
  display: block;
  height: 280px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .homeSec02__list__item a {
    height: 220px;
  }
}
.homeSec02__list__item a:hover {
  opacity: 1;
}
.homeSec02__list__item a:hover::before {
  background: rgba(33, 79, 5, 0.48);
}
.homeSec02__list__item a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.48);
  transition: 0.3s;
}
.homeSec02__list__item a::after {
  border: 1px solid #fff;
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}
@media screen and (max-width: 767px) {
  .homeSec02__list__item a::after {
    top: 16px;
    left: 16px;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
  }
}
.homeSec02__list__item a img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.homeSec02__list__txtBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
}
.homeSec02__list__ttl {
  color: #fff;
  font-family: "Noto Serif JP", serif;
  font-size: 36px;
  font-weight: normal;
  line-height: 1.22;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .homeSec02__list__ttl {
    font-size: 24px;
  }
}
.homeSec02__list__txt {
  color: #fff;
  font-family: "Noto Serif JP", serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.22;
  text-align: center;
}

.homeSec03 {
  padding-top: 105px;
}
@media screen and (max-width: 767px) {
  .homeSec03 {
    padding-top: 69px;
  }
}
.homeSec03__inner {
  position: relative;
  height: 100%;
}
.homeSec03__txtBox {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 540px;
}
@media screen and (max-width: 767px) {
  .homeSec03__txtBox {
    position: static;
    transform: none;
  }
}
.homeSec03__txtBox__ttl {
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.83;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .homeSec03__txtBox__ttl {
    font-size: 18px;
    margin-top: 16px;
  }
}
.homeSec03__txtBox__txt {
  font-size: 14px;
  font-weight: normal;
  line-height: 2;
  margin-top: 32px;
}
@media screen and (max-width: 767px) {
  .homeSec03__txtBox__txt {
    margin-top: 30px;
  }
}
.homeSec03__btnBox {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .homeSec03__btnBox {
    margin: 20px auto 0;
  }
  .homeSec03__btnBox a {
    margin: auto;
  }
}
.homeSec03__imgBox {
  max-width: 941px;
  margin-left: auto;
}
.homeSec03__splideBox {
  margin-top: 40px;
  margin-left: -15px;
  width: calc(100% + 30px);
}

.homeSec04 {
  padding-top: 105px;
}
@media screen and (max-width: 767px) {
  .homeSec04 {
    padding-top: 80px;
  }
}
.homeSec04__box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;
  margin-top: 68px;
}
@media screen and (max-width: 767px) {
  .homeSec04__box {
    grid-template-columns: 1fr;
  }
}
.homeSec04__list {
  counter-reset: number;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 84px 33px;
}
@media screen and (max-width: 767px) {
  .homeSec04__list {
    gap: 73px 0;
    grid-template-columns: 1fr;
  }
}
.homeSec04__list__item {
  position: relative;
}
.homeSec04__list__item:nth-child(2) {
  margin-top: 80px;
}
.homeSec04__list__item:nth-child(3) {
  margin-top: -80px;
}
@media screen and (max-width: 767px) {
  .homeSec04__list__item:nth-child(2) {
    margin-top: 0;
  }
  .homeSec04__list__item:nth-child(3) {
    margin-top: 0;
  }
}
.homeSec04__list__item::before {
  counter-increment: number;
  content: "0" counter(number);
  font-family: "Noto Serif JP", serif;
  font-size: 48px;
  letter-spacing: 0.06em;
  position: absolute;
  top: -48px;
  left: 5px;
}
@media screen and (max-width: 767px) {
  .homeSec04__list__item::before {
    left: 40px;
  }
}
.homeSec04__list__ttl {
  font-family: "Noto Serif JP", serif;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .homeSec04__list__ttl {
    margin: 20px 40px 0;
  }
}
.homeSec04__list__txt {
  font-size: 12px;
  font-weight: normal;
  line-height: 2;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .homeSec04__list__txt {
    margin: 10px 40px 0;
  }
}
.homeSec04__txtBox__ttl {
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.83;
}
@media screen and (max-width: 767px) {
  .homeSec04__txtBox__ttl {
    font-size: 18px;
  }
}
.homeSec04__txtBox__txt {
  font-size: 14px;
  font-weight: normal;
  line-height: 2;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .homeSec04__txtBox__txt {
    margin-top: 22px;
  }
}
.homeSec04__contactBox {
  border: solid 2px #214F05;
  color: #214F05;
  font-family: "Noto Serif JP", serif;
  margin-top: 40px;
  padding: 23px;
}
@media screen and (max-width: 767px) {
  .homeSec04__contactBox {
    padding: 23px 0;
    margin-top: 30px;
  }
}
.homeSec04__contactBox__txt {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
}
.homeSec04__contactBox__tel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 32px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 17px;
}
@media screen and (max-width: 767px) {
  .homeSec04__contactBox__tel {
    font-size: 27px;
  }
}
.homeSec04__contactBox__mail {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .homeSec04__contactBox__mail {
    font-size: 21px;
  }
}
.homeSec04__contactBox__line {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #000;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .homeSec04__contactBox__line {
    font-size: 14px;
  }
}

.homeSec05 {
  padding-top: 150px;
}
@media screen and (max-width: 767px) {
  .homeSec05 {
    padding-top: 65px;
  }
  .homeSec05__box {
    display: flex;
    flex-direction: column-reverse;
  }
}
.homeSec05__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 32px;
  margin-top: 32px;
}
@media screen and (max-width: 767px) {
  .homeSec05__list {
    grid-template-columns: 1fr;
  }
}
.homeSec05__list__item {
  border-top: solid 1px #D0D0D0;
  font-size: 14px;
  display: grid;
  grid-template-columns: 200px auto;
  padding: 20px 0;
}
@media screen and (max-width: 767px) {
  .homeSec05__list__item {
    grid-template-columns: 160px auto;
  }
}
.homeSec05__list__item:nth-last-child(2) {
  border-bottom: solid 1px #D0D0D0;
}
@media screen and (max-width: 767px) {
  .homeSec05__list__item:nth-last-child(2) {
    border-bottom: none;
  }
}
.homeSec05__list__item:last-child {
  border-bottom: solid 1px #D0D0D0;
}
.homeSec05__list__ttl {
  font-weight: normal;
}
@media screen and (max-width: 767px) {
  .homeSec05__list__ttl {
    font-size: 14px;
  }
}
.homeSec05__list__txt {
  font-size: 14px;
  font-weight: normal;
}
.homeSec05__mapBox {
  margin-top: 32px;
}
.homeSec05__mapBox iframe {
  width: 100%;
  height: 400px;
}
@media screen and (max-width: 767px) {
  .homeSec05__mapBox iframe {
    height: 212px;
  }
}

.serviceSec01 {
  padding-top: 100px;
}
@media screen and (max-width: 767px) {
  .serviceSec01 {
    padding-top: 60px;
  }
}
.serviceSec01__list {
  border-left: 1px solid #D0D0D0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
}
@media screen and (max-width: 767px) {
  .serviceSec01__list {
    border-left: none;
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
.serviceSec01__list__item {
  font-family: "Noto Serif JP", serif;
  border-right: 1px solid #D0D0D0;
}
@media screen and (max-width: 767px) {
  .serviceSec01__list__item {
    border-left: 1px solid #D0D0D0;
  }
}
.serviceSec01__list__item a {
  background: url(../img/common/ico_arrow02.svg) no-repeat center bottom/26px 26px;
  display: block;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 33px;
}
.serviceSec01__list__item a span {
  display: block;
  font-size: 12px;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .serviceSec02 {
    padding-top: 40px;
  }
}
.serviceSec02__box {
  display: grid;
  grid-template-columns: 560px auto;
  gap: 80px;
  padding-top: 80px;
}
@media screen and (max-width: 767px) {
  .serviceSec02__box {
    grid-template-columns: 1fr;
    gap: 40px;
    padding-top: 40px;
  }
}
.serviceSec02__box.is_opposite {
  grid-template-columns: auto 560px;
}
.serviceSec02__box:nth-child(n+2) {
  border-top: 1px solid #D0D0D0;
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .serviceSec02__box:nth-child(n+2) {
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .serviceSec02__box {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .serviceSec02__box.is_opposite {
    grid-template-columns: 1fr;
  }
}
.serviceSec02__splideBox .splide__arrow svg {
  display: none;
}
.serviceSec02__splideBox .splide__arrow--prev {
  background: url(../img/common/ico_arrow_left01.svg) no-repeat center center/26px 32px;
  border-radius: 0;
  left: -3px;
  width: 26px;
  height: 32px;
}
.serviceSec02__splideBox .splide__arrow--next {
  background: url(../img/common/ico_arrow_right01.svg) no-repeat center center/26px 32px;
  border-radius: 0;
  right: -3px;
  width: 26px;
  height: 32px;
}
.serviceSec02__splideBox .splide__slide {
  max-width: 560px;
}
@media screen and (max-width: 767px) {
  .serviceSec02__splideBox .splide__slide {
    max-width: 100%;
  }
}
.serviceSec02__splideBox .splide__pagination {
  bottom: -20px;
  right: auto;
  padding: 0;
}
.serviceSec02__splideBox .splide__pagination__page {
  background: #CCCCCC;
  border-radius: 0;
  width: 40px;
  height: 4px;
}
.serviceSec02__splideBox .splide__pagination__page.is-active {
  background: #214F06;
  transform: scale(1);
}
@media screen and (max-width: 767px) {
  .serviceSec02__imgBox {
    order: 2;
  }
}
.serviceSec02__txtBox {
  font-family: "Noto Serif JP", serif;
}
@media screen and (max-width: 767px) {
  .serviceSec02__txtBox {
    order: 1;
  }
}
.serviceSec02__txtBox__ttl {
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0.06em;
}
.serviceSec02__txtBox__ttl span {
  display: block;
  font-size: 16px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .serviceSec02__txtBox__ttl {
    font-size: 24px;
  }
  .serviceSec02__txtBox__ttl span {
    font-size: 14px;
  }
}
.serviceSec02__txtBox__txt {
  font-size: 24px;
  font-weight: 400;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .serviceSec02__txtBox__txt {
    font-size: 16px;
    margin-top: 20px;
  }
}
.serviceSec02__txt {
  font-size: 14px;
  line-height: 2;
  margin-top: 35px;
}

.worksSec01 {
  padding-top: 100px;
}
.worksSec01__list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
@media screen and (max-width: 767px) {
  .worksSec01__list__item {
    width: calc(50% - 6px);
  }
}
.worksSec01__list__item a {
  border: 1px solid #214F06;
  font-family: "Noto Serif JP", serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  height: 40px;
}
.worksSec01__list__item a:hover, .worksSec01__list__item a.is_active {
  background-color: #214f06;
  color: #fff;
  cursor: pointer;
  opacity: 1;
}

.worksSec02 {
  margin-top: 24px;
}
.worksSec02__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px 12px;
}
.worksSec02__list a {
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .worksSec02__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.mfp-content {
  max-width: 1120px;
}

@media screen and (max-width: 767px) {
  .mfp-arrow-left {
    left: -10px;
  }
}

@media screen and (max-width: 767px) {
  .mfp-arrow-right {
    right: -10px;
  }
}

@media screen and (max-width: 767px) {
  .mfp-close {
    right: auto;
    left: 0;
  }
}

.popup__box {
  display: flex;
  gap: 40px;
}
@media screen and (max-width: 767px) {
  .popup__box {
    flex-direction: column;
  }
}
.popup__imgBox {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .popup__imgBox {
    width: 100%;
  }
}
.popup__txtBox {
  color: #fff;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .popup__txtBox {
    margin: 0 auto;
    width: 80%;
  }
}
.popup__txtBox__ttl {
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
  font-weight: normal;
}
.popup__txtBox__txt {
  font-size: 14px;
  font-weight: normal;
  margin-top: 30px;
}
.popup__list {
  margin-top: 20px;
}
.popup__list__item {
  display: grid;
  grid-template-columns: 124px auto;
  border-bottom: 1px solid #fff;
  padding: 10px 0;
}
.popup__list__ttl {
  font-size: 14px;
  font-weight: normal;
}
.popup__list__txt {
  font-size: 14px;
  font-weight: normal;
}