@charset "UTF-8";
@import "_define.scss";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Serif+JP:wght@200..900&display=swap');

/*============================================================================================================
	base
============================================================================================================*/

body {
	color: #000;
	line-height: 2;
	height: 100%;
	min-width: 1000px;
	@include mq {
		min-width: auto;
  }
}

div,h1,h2,h3,h4,h5,p,li,dl,dt,dd,th,td,article,header,footer,nav,span,a {
	box-sizing: border-box;
}

li {
	list-style: none;
}

a {
	color: inherit;
	text-decoration: none;
	transition: 0.3s;
}

img {
	height: auto;
	width: auto;
	max-width: 100%;
	line-height: 100%;
	vertical-align: bottom;
}

em {
	font-style: normal;
}

h1,h2,h3,h4,h5 {
	line-height: 1.4;
	margin: 0;
	word-wrap: break-word;
}

p,li,td,th,dt,dd {
	font-weight: 500;
	font-size: 20px;
	line-height: 1.85;
	letter-spacing: .005em;
	margin: 0;
	word-wrap: break-word;
}

table {
	width: 100%;
	border-collapse: collapse;
}

th,
td {
	text-align: left;
	font-weight: normal;
	vertical-align: top;
	word-break: break-all;
}

ul,
ol,
dl {
	padding: 0;
	margin: 0;
}

* {
	box-sizing: border-box;
}

/*	PC・SP 表示指定 */

@media screen and (min-width: $breakpoint_pc_min) {
	.is_sp {
		display: none !important;
	}
}

@media screen and (max-width: $breakpoint_tablet) {
	.is_pc {
		display: none !important;
	}
}


/*------------------------------------------
	font
------------------------------------------*/

body {
	font-family: $gothic;
}

/*------------------------------------------
	hover
------------------------------------------*/
a {
	@include transition;
	&:hover {
		opacity: .7;
	}
}

/*	枠内にfitするimg */

.imgFitBox, .imgLinkBox {
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover; object-position: center;';
	}
	&.contain {	/* fit = contain */
		img {
			object-fit: contain;
			font-family: 'object-fit: contain; object-position: center;';
		}
	}
}

/*	hoverで拡大するimg */
.imgLinkBox {
	display: block;
	overflow: hidden;
	@media screen and (min-width: $breakpoint_pc_min) {
		img {
			@include transition(all 0.3s ease-in-out);
		}
		&:hover {
			filter: alpha(opacity=100);
			 -moz-opacity: 1;
			opacity: 1;
			img {
				@include transform(scale(1.05));
			}
		}
	}
}

.main {
	padding-bottom: 120px;
	@include mq {
		padding-bottom: 84px;
	}
}

/*	inner */
.c-inner01 {
	padding: 0 15px;
	margin: 0 auto;
	max-width: 1150px;
	width: 100%;
}

.c-inner02 {
	margin: 0 auto;
	max-width: 900px;
	width: 100%;
}

/*	セクションタイトル */
.c-ttl01 {
	display: flex;
	align-items: center;
	font-family: $mincho;
	font-weight: normal;
	font-size: 40px;
	letter-spacing: .06em;
	&.is_white {
		color: #fff;
	}
	span {
		font-size: 16px;
		margin-left: 10px;
	}
	@include mq {
		font-size: 32px;
		span {
			font-size: 10px;
		}
	}
}

/*	ボタン	*/
.c-btn01 {
	background: #214F05;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: $mincho;
	font-size: 15px;
	max-width: 256px;
	width: 100%;
	height: 56px;
	&.is-center {
		margin: 0 auto;
	}
	@include mq {
	}
}
