/*	header */
.header {
	background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
	position: fixed;
	top: 0;
	left: 0;
	padding-right: 40px;
	z-index: 999;
	width: 100%;
  height: 80px;
	@include mq {
		height: 50px;
	}
	&.lower_header {
		margin-bottom: 0;
		position: static;
		&::before {
			content: none;
		}
	}
	&__logo {
		display: block;
		max-width: 180px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
		@include mq {
			max-width: 106px;
		}
	}
	&__nav {
		opacity: 1;
		visibility: visible;
		z-index: 1999990;
		overflow-y: scroll;
		@include mq {
			padding: 14px 16px;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #fff;
			z-index: 99;
			opacity: 0;
			visibility: hidden;
			z-index: -1;
			transition: all .3s ease-in-out;
			&.close {
				opacity: 1;
				visibility: visible;
				z-index: 99;
			}
		}
		&__line {
			font-size: 14px;
			font-family: $mincho;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 7px;
			margin-top: 9px;
		}
		&__copy {
			font-size: 12px;
			font-family: $mincho;
			text-align: center;
			margin-top: 12px;
		}
	}
	&__navList {
		display: flex;
    align-items: center;
		justify-content: space-between;
		@include mq {
			flex-direction: column;
			align-items: flex-start;
			margin-top: 30px;
		}
		&__item {
			@include mq {
				width: 100%;
				a {
					border-bottom: solid 1px #ccc;
					display: block;
					padding-top: 18px;
					padding-bottom: 18px;
					width: 100%;
				}
			}
			&.is_has_child {
				@include mq {
					position: relative;
					margin-bottom: 0;
					&::after, &::before {
						background-color: #000;
						content: '';
						position: absolute;
						top: 30px;
						right: 10px;
						width: 12px;
						height: 1px;
					}
					&::after {
						transform: rotate(90deg);
					}
					&.is_open {
						&::after {
							opacity: 0;
						}
					}
				}
			}
			& + & {
				margin-left: 48px;
				@include mq {
					margin-left: 0;
				}
			}
      &.is_btn {
        a {
					border: solid 1px #214F05;
          background-color: #214F05;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 160px;
          height: 40px;
					&:hover {
						background-color: #fff;
						color: #214F05;
					}
        }
      }
			a {
				font-size: 15px;
        font-family: $mincho;
				&:hover {
					color: #214F05;
				}
			}
		}
	}
	&__navSubList {
		display: none;
		&__item {
			width: 100%;
			&:last-child {
				margin-bottom: 18px;
			}
			a {
				background: url(../img/common/ico_arrow01.svg) no-repeat right 5px center / 24px;
				border-bottom: solid 1px #ccc;
				padding-left: 24px;
				width: 100%;
			}
		}
	}
	&__contactBox {
		border: solid 2px #214F05;
		font-family: $mincho;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 16px;
		&__txt {
			font-size: 14px;
			text-align: center;
		}
		&__tel {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 7px;
			font-size: 28px;
			font-weight: normal;
			line-height: 1;
		}
		&__mail {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 7px;
			font-size: 24px;
			font-weight: normal;
			line-height: 1;
		}
		& + & {
			margin-top: 12px;
		}
	}
}

/*	sp メニューを開くボタン */
.navBtn {
	background: transparent;
	border: none;
	transition: all .3s ease-in-out;
	cursor: pointer;
	width: 24px;
	height: 18px;
	top: 16px;
	right: 16px;
	position: fixed;
	text-align: center;
	z-index: 99999;
	a {
		height: 100%;
		display: block;
	}
	&, span {
		display: inline-block;
		transition: all .3s ease-in-out;
	}
	span {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		width: 100%;
		height: 1px;
		background: #000;
		&:nth-of-type(1) {
			top: 0;
		}
		&:nth-of-type(2) {
			top: 0;
			bottom: 0;
		}
		&:nth-of-type(3) {
			bottom: 0;
		}
	}
	&.close {
		span {
			&:nth-of-type(1) {
				transform: rotate(45deg);
				top: 8px;
			}
			&:nth-of-type(2) {
				opacity: 0;
			}
			&:nth-of-type(3) {
				transform: rotate(-45deg);
				bottom: 50%;
			}
		}
	}
}



