@charset "UTF-8";
@import "_define.scss";

.serviceSec01 {
  padding-top: 100px;
  @include mq {
    padding-top: 60px;
  }
  &__list {
    border-left: 1px solid #D0D0D0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0;
    @include mq {
      border-left: none;
      grid-template-columns: 1fr;
      gap: 15px;
    }
    &__item {
      font-family: $mincho;
      border-right: 1px solid #D0D0D0;
      @include mq {
        border-left: 1px solid #D0D0D0;
      }
      a {
        background: url(../img/common/ico_arrow02.svg) no-repeat center bottom / 26px 26px;
        display: block;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        padding-bottom: 33px;
        span {
          display: block;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}

.serviceSec02 {
  @include mq {
    padding-top: 40px;
  }
  &__box {
    display: grid;
    grid-template-columns: 560px auto;
    gap: 80px;
    padding-top: 80px;
    @include mq {
      grid-template-columns: 1fr;
      gap: 40px;
      padding-top: 40px;
    }
    &.is_opposite {
      grid-template-columns: auto 560px;
    }
    &:nth-child(n + 2) {
      border-top: 1px solid #D0D0D0;
      margin-top: 80px;
      @include mq {
        margin-top: 40px;
      }
    }
    @include mq {
      grid-template-columns: 1fr;
      gap: 40px;
      &.is_opposite {
        grid-template-columns: 1fr;
      }
    }
  }
  &__splideBox {
    .splide__arrow svg {
      display: none;
    }
    .splide__arrow--prev {
      background: url(../img/common/ico_arrow_left01.svg) no-repeat center center / 26px 32px;
      border-radius: 0;
      left: -3px;
      width: 26px;
      height: 32px;
    }
    .splide__arrow--next {
      background: url(../img/common/ico_arrow_right01.svg) no-repeat center center / 26px 32px;
      border-radius: 0;
      right: -3px;
      width: 26px;
      height: 32px;
    }
    .splide__slide {
      max-width: 560px;
      @include mq {
        max-width: 100%;
      }
    }
    .splide__pagination {
      bottom: -20px;
      right: auto;
      padding: 0;
    }
    .splide__pagination__page {
      background: #CCCCCC;
      border-radius: 0;
      width: 40px;
      height: 4px;
      &.is-active {
        background: #214F06;
        transform: scale(1);
      }
    }
  }
  &__imgBox {
    @include mq {
      order: 2;
    }
  }
  &__txtBox {
    font-family: $mincho;
    @include mq {
      order: 1;
    }
    &__ttl {
      font-size: 32px;
      font-weight: 400;
      letter-spacing: 0.06em;
      span {
        display: block;
        font-size: 16px;
        font-weight: 400;
      }
      @include mq {
        font-size: 24px;
        span {
          font-size: 14px;
        }
      }
    }
    &__txt {
      font-size: 24px;
      font-weight: 400;
      margin-top: 40px;
      @include mq {
        font-size: 16px;
        margin-top: 20px;
      }
    }
  }
  &__txt {
    font-size: 14px;
    line-height: 2;
    margin-top: 35px;
  }
}
