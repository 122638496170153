$gothic: 'Noto Sans JP', '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3', 'メイリオ', Meiryo, sans-serif;
$mincho: 'Noto Serif JP', serif;

$breakpoint_pc_min: 768px;
$breakpoint_tablet: 767px;

@mixin transition($value) {
		-webkit-transition: $value;
		-moz-transition: $value;
		-o-transition: $value;
	transition: $value;
}

@mixin transform($value) {
		-webkit-transform: $value;
		-ms-transform: $value;
	transform: $value;
}

$mq-breakpoints: (
  'pc': 'min-width: 768px',
  'sp': 'max-width: 767px',
  'sp02': 'max-width: 320px',
);

@mixin mq($breakpoint: sp) {
  @media screen and (#{map-get($mq-breakpoints, $breakpoint)}) {
    @content;
  }
}

@mixin transition($value: .3s) {
	transition: all $value ease-in-out;
}

@function get_vw($size, $viewport: 375) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@function get_vw_pc($size, $viewport: 675) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10){
  @include mq(pc) {
    font-size: $font_size * 1px;
  }
  @include mq(sp) {
    font-size: $font_size * 1px;
    font-size: get_vw($font_size);
  }
}